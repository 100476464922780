import React from "react";

import wabmLogo from "./wabm-logo.png";

const RedirectToWabm = () => {
  return (
    <div className="content">
      <img src={wabmLogo} alt="WaBM by Infokey" width="240px" />
      <h1>WaBM App has been moved</h1>
      <p>
        WaBM WhatsApp Business Marketing tool app has been migrated to
        app.wabm.in. Please click the link below to redirect.
      </p>
      <button class="wabm-btn">
        <a href="https://app.wabm.in">Visit WaBM App</a>
      </button>
    </div>
  );
};

export default RedirectToWabm;
